import axios from 'axios';
import {create} from 'zustand'

export const useBookingStore = create((set)=>({
    branches: [],
    availableRoomList: [],
    avaliableRoomLoadingState: false,

    setBranch: async()=>{
        const { data } = await axios.get('/tuna_hms.api.getCompany')
        set({branches: await data.message})
    },

    setAvailableRoom: async (checkIn, checkOut, company) => {
        set({ avaliableRoomLoadingState: true });
        const response = await axios.post(
          "/tuna_hms.api.avaliableRoom",
          { checkIn: `${checkIn}T00:00:00.000000`, checkOut: `${checkOut}T00:00:00.000000`, company: company },
          // { withCredentials: true }
        );
        if (response.status < 300) {
          set({ availableRoomList: response.data.message });
          set({ avaliableRoomLoadingState: false });
        } else {
          set({ errorState: true, errorMessage: "Something went wrong" });
        }
      },
}))