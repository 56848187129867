import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import RomImage from '../../assets/images/brand/super-delux-rooms.jpg';
import { useCookies } from 'react-cookie';
import { logout } from '../Api/authentication';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'company', 'user_id', 'full_name']);
  const navigate = useNavigate();
  return (
    <div>
      <Container>
        <section className='accountWrapper'>
          <section className='myAccount'>
            <h5>My Account</h5>

            <ListGroup variant='flush'>
              <ListGroup.Item>Name: {cookies.full_name}</ListGroup.Item>
              <ListGroup.Item>Email: {cookies.user_id}</ListGroup.Item>
              <ListGroup.Item>Phone Number: 9844724666</ListGroup.Item>
              <ListGroup.Item>Address: Butwal Horizonchowk</ListGroup.Item>
            </ListGroup>
            <button onClick={async (e)=>{
              e.preventDefault()
              await logout(cookies.token);
              removeCookie('token',{path: "/"})
              removeCookie('company',{path: "/"})
              removeCookie('user_id',{path: "/"})
              removeCookie('full_name',{path: "/"})

              navigate('/')
            }}>Logout</button>
          </section>

          <section className='myBookings'>
            <h5>My Bookings</h5>
            <ul>
              <li>
                <section className='bookingCard'>
                  <figure className='bookingCard-image'>
                    <img src={RomImage} alt='' />
                  </figure>
                  <section className='bookingCard-info'>
                    <div className='bookingCard-info-title'>
                      {' '}
                      Grand Delux Room <span>Tuna pauwa Bhairahawa</span>
                    </div>

                    <div className='bookingCard-info-dates'>
                      14/10/2023 to 16/10/2023 <span>2 rooms</span>
                    </div>
                    <div className='bookingCard-info-rooms'>1 Room</div>
                    <div className='bookingCard-info-price'>Rs 25000</div>
                  </section>
                </section>
              </li>
              <li>
                <section className='bookingCard'>
                  <figure className='bookingCard-image'>
                    <img src={RomImage} alt='' />
                  </figure>
                  <section className='bookingCard-info'>
                    <div className='bookingCard-info-title'>
                      {' '}
                      Grand Delux Room <span>Tuna pauwa Bhairahawa</span>
                    </div>

                    <div className='bookingCard-info-dates'>
                      14/10/2023 to 16/10/2023 <span>2 rooms</span>
                    </div>
                    <div className='bookingCard-info-rooms'>1 Room</div>
                    <div className='bookingCard-info-price'>Rs 25000</div>
                  </section>
                </section>
              </li>
            </ul>
          </section>
        </section>
      </Container>
    </div>
  );
};

export default Account;
