import axios from "axios";

export const addBooking = async (company, checkIn, checkOut, roomType, roomToBook, token) => {
    try {
        const response = await axios.post("/tuna_hms.api.addMobileBooking", {
            company: company,
            room_type: roomType.name,
            check_in: `${checkIn}T00:00:00.000000`,
            check_out: `${checkOut}T00:00:00.000000`,
            room_package: roomType.room_package,
            total_advance_paid: 0,
            payment_mode: "Cash",
            room_to_book: roomToBook,
            reference_no: "",
            reference_date: ""
        }, {
            headers: {
              "Authorization": `Basic ${token}`,
              "content-type": "application/x-www-form-urlencoded",
            },
          }
        )

        if (response.data.message.success === true) {
            return response.data.message
        } else {
            return false
        }
    } catch (error) {
        console.log(error);
        return false
    }
}