import React, { useState } from 'react';
import { Container, Form, ListGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBookingStore } from '../../stores/bookingStore';
import { useCookies } from 'react-cookie';

const Booking = () => {
  const location = useLocation()
  const { checkIn, checkOut, selectBranch, roomType } = location.state;

  const roomArray = Array.from({ length: roomType.availableRoom });

  const [cookies] = useCookies(['full_name, user_id', 'mobile_no'])
  const [roomToBook, setRoomToBook] = useState(1);

  const calculateDateDifference = () => {
    const startDateObject = new Date(checkIn);
    const endDateObject = new Date(checkOut);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDateObject - startDateObject;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays;
  };

  const navigate = useNavigate()

  return (
    <div>
      <Container>
        <section className='bookingWrapper'>
          <section className='bookingWrapper-roomDetails'>
            <h5>Room Details</h5>

            <section className='bookingWrapper-roomDetails-info'>
              Room Type: Standard Delux Room <br /> {checkIn} - {checkOut}{' '}
              <br /> {calculateDateDifference()} nights <br />
              <div className='roomSelectBox'>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label>How many rooms do you want ?</Form.Label>
                  <Form.Select aria-label='Default select example' onChange={(e) => setRoomToBook(e.target.value)}>
                    {roomArray.map((_, index) => {
                      return <option value={`${index + 1}`}>{index + 1}</option>
                    })}
                  </Form.Select>
                </Form.Group>
              </div>
            </section>
          </section>
          <section className='bookingWrapper-customerDetials'>
            <h5>Customer Details</h5>
            {/* <Form.Check label='I am a guest in this room' /> */}

            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Guest Name</Form.Label>
              <Form.Control value={cookies.full_name} type='email' placeholder='guest name here' readOnly={true} />
            </Form.Group>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type='email' placeholder="Guest's phone number" value={cookies.mobile_no} readOnly={true} />
            </Form.Group>
          </section>
          <section className='bookingWrapper-billDetails'>
            <h5>Bill details</h5>

            <ListGroup>
              <ListGroup.Item>
                {roomType.room_type_name}  X {roomToBook} rooms X {calculateDateDifference()} nights:{' '}
                <strong> Rs. {roomType.price * roomToBook * calculateDateDifference()}</strong>
              </ListGroup.Item>
              <ListGroup.Item>
                Tax: <strong>Rs. {Math.round(roomType.price * roomToBook * calculateDateDifference() * 0.13)} </strong>{' '}
              </ListGroup.Item>
              <ListGroup.Item>
                Grand Total: <strong>Rs. {roomType.price * roomToBook * calculateDateDifference() + Math.round(roomType.price * roomToBook * calculateDateDifference() * 0.13)} </strong>{' '}
              </ListGroup.Item>
            </ListGroup>
          </section>


          <button className='bton bton--md bton--primary' onClick={(e) => {
            e.preventDefault()
            navigate('/payment', { state: { checkIn, checkOut, selectBranch, roomType, roomToBook } })
          }}>
            Proceed to payment
          </button>
        </section>
      </Container>
    </div>
  );
};

export default Booking;
