import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LogoWhite from '../../assets/images/brand/brand.svg';
import { FaFacebookF } from 'react-icons/fa';
import { FiTwitter } from 'react-icons/fi';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <section role='Footer'>
        <Container>
          <Row className='justify-content-between'>
            <Col md={6} lg={3}>
              <section className='foooterInfo'>
                <header className='Links-title'>
                  <img src={LogoWhite} alt='' />
                </header>
                <p>
                  Welcome to one of the most iconic hotel. Hotel Pauwa is
                  established to provide the place to stay and take rest with
                  the environment of Nepalese taste, culture and hospitality to
                  all the people across the globe.
                </p>
              </section>
            </Col>
            <Col md={6} lg={2}>
              <section className='Links'>
                <header className='Links-title'>
                  <h3>Links</h3>
                </header>

                <ul className='Links-box'>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/about'> </Link> About Us
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/products'> </Link> Products
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/'> </Link> Terms and conditons
                  </li>
                  <li className='Links-box-item'>
                    {' '}
                    <Link to='/'> </Link> Privacy Policy
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={6} lg={3}>
              <section className='contact'>
                <header className='contact-title'>
                  <h3>Customer Support</h3>
                </header>

                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Corporate Office</strong>{' '}
                      </div>
                      <div className='addHolder-value '>Yogikuti Butwal </div>
                    </div>
                  </li>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-phone'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Phone</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        071-437245, 071-573602
                      </div>
                    </div>
                  </li>

                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-envelope'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Email</strong>{' '}
                      </div>
                      <div className='addHolder-value '>
                        branding@hotelpauwa.com
                      </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
            <Col md={6} lg={3}>
              <section className='socialMedia'>
                <header className='socialMedia-title'>
                  <h3>Customer Support</h3>
                </header>

                <section className='socialWrapper'>
                  <a
                    href={'https://www.facebook.com/pauwahotel/'}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <FaFacebookF />
                    </div>
                  </a>

                  <a
                    href={'https://www.instagram.com/hotelpauwa/?hl=en'}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <FaInstagram />
                    </div>
                  </a>
                  {/* <a
                    href={'https://twitter.com/paichopasal'}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='socialWrapper-icons'>
                      <FiTwitter />
                    </div>
                  </a> */}
                </section>
              </section>
              <section className='contact'>
                <ul className='contact-box'>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong>Krishna Gautam </strong>{' '}
                      </div>
                      <div className='addHolder-key'>
                        <strong>Chief operation officer </strong>{' '}
                      </div>
                      <div className='addHolder-value '>9857072512 </div>
                    </div>
                  </li>
                  <li className='contact-box-item'>
                    <div className='iconHolder'>
                      <i className='fa fa-map-marker'></i>
                    </div>
                    <div className='addHolder'>
                      <div className='addHolder-key'>
                        <strong> Bishnu Bhandari</strong>{' '}
                      </div>
                      <div className='addHolder-key'>
                        <strong>Chief branding officer</strong>{' '}
                      </div>
                      <div className='addHolder-value '>9857072511 </div>
                    </div>
                  </li>
                </ul>
              </section>
            </Col>
          </Row>

          <hr />
          <section className='copyright'>
            Tuna Hms &copy; {new Date().getFullYear()} All Rights Reserved
          </section>
        </Container>
      </section>
    </>
  );
};

export default Footer;
