import { Carousel, Container, Row, Col, Form } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Gallery1 from '../../assets/images/brand/gallery1.png';
import gql from 'graphql-tag';
import { useNavigate, useParams } from 'react-router-dom';
import Query from '../../components/Query';
// import Rooms from "../../components/Rooms";
// import RoomImage from "../../assets/images/brand/super-delux-rooms.jpg";
// import { Link } from "react-router-dom";

const RoomDescription = () => {
  const [roomName, setRoomName] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [selectBranch, setSelectBranch] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const GET_ROOMDESC = gql`
    query NewQuery($id: ID!) {
      room(id: $id, idType: SLUG) {
        rooms {
          detaildesc
          description
          price
          name
          image {
            sourceUrl
          }
          gallery {
            sourceUrl
          }
        }
      }
    }
  `;
  const formHandler = (e) => {
    e.preventDefault();
    navigate('/reservationform', {
      state: { checkIn, checkOut, selectBranch, roomName },
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Query query={GET_ROOMDESC} id={id}>
        {({ data: { room } }) => {
          setRoomName(room?.rooms?.name);
          return (
            <Container>
              <div className='roomdesc'>
                <p className='offer mt-5'>LEARN MORE</p>:
                <h1>{room?.rooms?.name}</h1>
                <p className='roomdesc__desc'>
                  {/* 30m<sup>2</sup> . 2 Beds. 2 Guests */}
                  {room?.rooms?.description}
                </p>
                <div className='roomdesc__slider'>
                  <Carousel fade>
                    {room.rooms?.gallery?.map((a) => {
                      return (
                        <Carousel.Item>
                          <img
                            className='d-block w-100'
                            src={a?.sourceUrl}
                            alt='First slide'
                          />
                          <Carousel.Caption>
                            <h3>01</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
                <div className='roomdesc__description'>
                  <Row className='gx-3'>
                    <Col md={8}>
                      <div className='roomdesc__description--leftbox'>
                        <div className='roomdesc__description--leftbox--heading'>
                          <p>About This Room</p>
                          {/* <p>Rs.1500/Night</p> */}
                          <p>{room?.rooms?.price}</p>
                        </div>
                        <div className='roomdesc__description--leftbox--about'>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: room.rooms?.detaildesc,
                            }}
                          >
                            {/* Facilisis rhoncus id phasellus massa urna, diam volutpat
                      lectus a. Enim duis cras ullamcorper rhoncus diam
                      faucibus. Quisque enim morbi faucibus ultrices bibendum
                      id. Odio mattis ultrices tellus et orci turpis augue.
                      Aliquet tellus sit elit turpis in gravida. Non nullam
                      senectus in iaculis faucibus. Fusce non donec euismod nam
                      elementum lacus et. Nisl phasellus turpis aenean donec
                      dui. */}
                            {/* {room.rooms?.detaildesc} */}
                          </p>
                        </div>
                        {/* <div className="roomdesc__description--leftbox--facility">
                    <p>Rooms Facilities</p>
                    <ul>
                      <li>Flat screen TV</li>
                      <li>Kettle, tea & Coffee</li>
                      <li>Breakfast included</li>
                      <li>Shower-bathtub combination</li>
                      <li>Hairdryer</li>
                      <li>Telephone</li>
                      <li>Towel warmer</li>
                      <li>Fresh mineral water and fresh fruits</li>
                    </ul>
                  </div> */}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='roomdesc__description--reservation'>
                        <p>Reservation</p>
                        <Form onSubmit={formHandler}>
                          <Form.Label>When(Check IN)</Form.Label>
                          <Form.Group
                            className='mb-4'
                            controlId='formBasicEmail'
                          >
                            <Form.Control
                              type='date'
                              value={checkIn}
                              onChange={(e) => setCheckIn(e.target.value)}
                            />
                          </Form.Group>

                          <Form.Label>How Long(Check OUT)</Form.Label>
                          <Form.Group
                            className='mb-4'
                            controlId='formBasicEmail'
                          >
                            <Form.Control
                              type='date'
                              value={checkOut}
                              onChange={(e) => setCheckOut(e.target.value)}
                            />
                          </Form.Group>

                          <Form.Label>Choose Branches</Form.Label>
                          <Form.Select
                            aria-label='Branches'
                            value={selectBranch}
                            onChange={(e) => setSelectBranch(e.target.value)}
                          >
                            <option> Choose Branch</option>

                            <option value='Butwal Branch'>Butwal Branch</option>
                            <option value='Bhairahawa Branch'>
                              Bhairahawa Branch
                            </option>
                            <option value='Dhangadi Branch'>
                              Dhangadi Branch
                            </option>
                            <option value='Resunga Branch'>
                              Resunga Branch
                            </option>
                            <option value='Bhalubang Branch'>
                              Bhalubang Branch
                            </option>
                            <option value='Gorusinghe Branch'>
                              Gorusinghe Branch
                            </option>
                            <option value='Ghorai Branch'>Ghorai Branch</option>
                            <option value='Jumla Branch'>Jumla Branch</option>
                            <option value='Lamahi Branch'>Lamahi Branch</option>
                            <option value='Palpa Branch'>Palpa Branch</option>
                          </Form.Select>

                          <button
                            className='bton bton--md bton--primary book-nowbtn'
                            type='submit'
                          >
                            Book Now
                          </button>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <div className="roomdesc__morerooms">
            <p className="roomdesc__morerooms--title">More Rooms</p>
            <Row>
                <Col md={6}>
                  <section className="roomCard">
                    <figure className="roomCard-image">
                      <img src={RoomImage} alt="" />
                    </figure>
                    <h2 className="roomCard-title">standard twin room</h2>

                    <section className="roomCard-attributes">
                      <span>
                        30m<sup>2</sup> .
                      </span>
                      <span>2 Beds .</span>
                      <span>2 Guests </span>
                    </section>

                    <section className="text-center pb-3">
                      <Link
                        to="/roomdescription"
                        className="bton bton--md bton--ghost"
                      >
                        Further Information
                      </Link>
                    </section>
                  </section>
                </Col>
                <Col md={6}>
                  <section className="roomCard">
                    <figure className="roomCard-image">
                      <img src={RoomImage} alt="" />
                    </figure>
                    <h2 className="roomCard-title">standard twin room</h2>

                    <section className="roomCard-attributes">
                      <span>
                        30m<sup>2</sup> .
                      </span>
                      <span>2 Beds .</span>
                      <span>2 Guests </span>
                    </section>

                    <section className="text-center pb-3">
                      <Link
                        to="/roomdescription"
                        className="bton bton--md bton--ghost"
                      >
                        Further Information
                      </Link>
                    </section>
                  </section>
                </Col>
            </Row>
          </div> */}
              </div>
            </Container>
          );
        }}
      </Query>
    </>
  );
};

export default RoomDescription;
