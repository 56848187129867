import axios from "axios";

export const login = async (email, password) => {
    try {
        const response = await axios.post("/sajha_menu.api.login", { 'usr': email, 'pwd': password, 'device': 'mobile' })

        if (!response.data.message.toString().includes("Authentication Error")) {
            return response.data.message

        } else {
            return false
        }
    } catch (error) {
        console.log(error);
        return false
    }
}

export const signUp = async (email, fullname, password, phone, address) => {
    try {
        const response = await axios.post("/sajha_menu.api.sign_up", { 'email': email, 'password': password, 'full_name': fullname, "mobile_no": phone, "location": address})

        if (!response.data.message.toString().includes("Authentication Error")) {
            return response.data.message
        } else {
            return false
        }
    } catch (error) {
        console.log(error);
        return false
    }
}



export const logout = async (token) => {
    try {
      const response = await axios.post(
        "/logout",
        {
          headers: {
            "Authorization": `Basic ${token}`,
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status < 300) {
  
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error);
      return "Something went wrong";
    }
  }
  