import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./ApolloClient";

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider  client={client}>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);
