import React from 'react';
import { Container } from 'react-bootstrap';
import { AiFillMail } from 'react-icons/ai';
import { TiLocation } from 'react-icons/ti';
import { RiPhoneFill } from 'react-icons/ri';

export default function Header() {
  return (
    <header className='headerWrapper'>
      <Container>
        <section className='topBar'>
          <article className='topBar-item'>
            <span className='topBar-item__icon'>
              <AiFillMail />
            </span>

            <span className='topBar-item__info'>info@tunahms.com</span>
          </article>
          <article className='topBar-item'>
            <span className='topBar-item__icon'>
              <TiLocation />
            </span>

            <span className='topBar-item__info'>Head Office, Butwal</span>
          </article>
          <article className='topBar-item'>
            <span className='topBar-item__icon'>
              <RiPhoneFill />
            </span>

            <span className='topBar-item__info'>
              +977 9857072511, 071-437245
            </span>
          </article>
        </section>
      </Container>
    </header>
  );
}
