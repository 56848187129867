import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Cookies, useCookies } from 'react-cookie';
import { login, signUp } from '../Api/authentication';
import { useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
  const [loginTab, setLoginTab] = useState(true);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [fullname, setFullname] = useState("")
  const [address, setAddress] = useState("")

  const cookie = new Cookies()

  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies(["token"]);

  
  useEffect(()=>{
    if(cookies.token){
      navigate('/')
    }
  },[])

  return (
    <div>
      <Container>
        {loginTab ? (
          <section className='loginWrapper'>
            <h3>Login</h3>
            <Form>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' placeholder='name@example.com' onChange={(e)=>setEmail(e.target.value)} />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Password</Form.Label>
                <Form.Control type='password' placeholder='password' onChange={(e)=>setPassword(e.target.value)}/>
              </Form.Group>

              <section className='text-center'>
                <button className='bton bton--primary bton--lg' onClick={async(e)=>{
                  e.preventDefault()
                  const res = await login(email, password)
                  if(res){
                    cookie.set('token', res.token, {path:'/'})
                    cookie.set('company', res.company,{path:'/'})
                    cookie.set('full_name', res.full_name,{path:'/'})
                    cookie.set('user_id', res.email,{path:'/'})
                    cookie.set('mobile_no', res?.mobile_no,{path:'/'})
                    cookie.set('location', res?.location, {path:'/'})

                    
                    if(location.state?.fromBooking){
                      navigate('/booking', {state:{...location.state}})
                    }else{
                      navigate('/');
                    }
                  }
                }}>Login</button>
                <section className='alreadyLogin'>
                  Dont have an account?{' '}
                  <span onClick={() => setLoginTab(false)}>Signup now</span>
                </section>
              </section>
            </Form>
          </section>
        ) : (
          <section className='loginWrapper'>
            <h3>Signup</h3>
            <Form>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Full Name</Form.Label>
                <Form.Control type='text' placeholder='Full name' onChange={(e)=>setFullname(e.target.value)} />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' placeholder='name@example.com' onChange={(e)=>setEmail(e.target.value)}  />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type='text' placeholder='Phone number'  onChange={(e)=>setPhone(e.target.value)} />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Password</Form.Label>
                <Form.Control type='password' placeholder='password' onChange={(e)=>setPassword(e.target.value)}  />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>Address</Form.Label>
                <Form.Control type='text' placeholder='Your address' onChange={(e)=>setAddress(e.target.value)} />
              </Form.Group>

              <section className='text-center'>
                <button className='bton bton--primary bton--lg' onClick={async (e)=>{
                        e.preventDefault()
                        const res = await signUp(email, fullname, password, phone, address)
                        if(res){
                          navigate('/login');
                        }
                }}>Signup</button>
                <section className='alreadyLogin'>
                  Already Have an account?{' '}
                  <span onClick={() => setLoginTab(true)}>Login now</span>
                </section>
              </section>
            </Form>
          </section>
        )}
      </Container>
    </div>
  );
};

export default Login;
