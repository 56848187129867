import React, { useEffect, useState } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../../components/Query';
import { useBookingStore } from '../../stores/bookingStore';
function Slider() {

  const GET_CAROUSEL = gql`
    query NewQuery {
      assets {
        nodes {
          title
          slug
          assets {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `;

  const fetchBranches = useBookingStore((state) => state.setBranch)
  const branches = useBookingStore((state) => state.branches)

  const fetchAvailableRooms = useBookingStore((state) => state.setAvailableRoom)

  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [selectBranch, setSelectBranch] = useState('');
  const [selectRoom, setSelectRoom] = useState('');
  const navigate = useNavigate();
  const formHandler = (e) => {
    e.preventDefault();
    navigate('/reservationform', {
      state: { checkIn, checkOut, selectBranch, selectRoom },
    });
    // if(checkIn || checkOut || selectBranch || selectRoom){
    //   navigate("/reservationform");
    // }
    // else{
    //   navigate("/rooms");
    // }
  };

  useEffect(() => {
    fetchBranches()
  }, [])

  return (
    <section className='carouselWrapper'>
      <Container>
        <Query query={GET_CAROUSEL}>
          {({ data: { assets } }) => {
            return (
              <>
                <Carousel>
                  {assets?.nodes[1]?.assets.image.map((a) => {
                    return (
                      <Carousel.Item>
                        <img
                          className='carouselWrapper-image'
                          src={a.sourceUrl}
                        />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </>
            );
          }}
        </Query>
        <div className='reservationform'>
          <Form onSubmit={formHandler}>
            <div className='d-lg-flex  justify-content-between align-items-center'>
              <div>
                <Form.Label>Check In</Form.Label>

                <Form.Control
                  type='date'
                  value={checkIn}
                  onChange={(e) => setCheckIn(e.target.value)}
                />
              </div>
              <div>
                <Form.Label>Check Out</Form.Label>

                <Form.Control
                  type='date'
                  value={checkOut}
                  onChange={(e) => setCheckOut(e.target.value)}
                />
              </div>

              <div>
                <Form.Label>Select Branch</Form.Label>

                <Form.Select
                  aria-label='Default select example'
                  onChange={(e) => setSelectBranch(e.target.value)}
                  value={selectBranch}
                >
                  <option> Choose Branch</option>
                  {branches?.map((a) => {
                    return <option value={a.name}>{a.name}</option>;
                  })}
                </Form.Select>
              </div>

              {/* <Query query={GET_ROOMS}>
                {({ data: { rooms } }) => {
                  return (
                    <>
                      <div>
                        <Form.Label>Select Room</Form.Label>

                        <Form.Select
                          aria-label='Default select example'
                          value={selectRoom}
                          onChange={(e) => setSelectRoom(e.target.value)}
                        >
                          <option>Any room</option>
                          {rooms.nodes?.map((a) => {
                            return <option value={a.title}>{a.title}</option>;
                          })}
                        </Form.Select>
                      </div>
                    </>
                  );
                }}
              </Query> */}
              <div>
                <Link to='/results'>
                  <button className='btonn bton--lg bton--primary mt-4' onClick={async (e) => {
                    e.preventDefault()
                    await fetchAvailableRooms(checkIn, checkOut, selectBranch)
                    navigate('/results', { state: { checkIn: checkIn, checkOut: checkOut, company: selectBranch } })
                  }}>
                    Book&nbsp;Now{' '}
                  </button>
                </Link>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </section>
  );
}

export default Slider;
