import React, { useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { FaCcPaypal } from 'react-icons/fa';
import { BsCashCoin } from 'react-icons/bs';
import { BiLogoInternetExplorer } from 'react-icons/bi';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addBooking } from '../Api/booking';
import { useCookies } from 'react-cookie';

const Payment = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation()
  const navigate = useNavigate();

  const [cookies] = useCookies(['token'])

  const {checkIn, checkOut, selectBranch, roomType,roomToBook} = location.state;

  const calculateDateDifference = () => {
    const startDateObject = new Date(checkIn);
    const endDateObject = new Date(checkOut);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDateObject - startDateObject;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays;
  };

  return (
    <div>
      <Container>
        <section className='paymentWrapper'>
          <h5>You are paying Rs {roomType.price * roomToBook * calculateDateDifference() + Math.round(roomType.price * roomToBook * calculateDateDifference() * 0.13)}</h5>
          Choose your preferred payment option
          <ul className='paymentBox'>
            <li className='paymentBox-items activePayment'>
              <BsCashCoin /> &nbsp; Cash
            </li>
            <li className='paymentBox-items'>
              {' '}
              <FaCcPaypal /> &nbsp; Paypal
            </li>
            <li className='paymentBox-items'>
              <BiLogoInternetExplorer /> &nbsp; Esewa
            </li>
          </ul>
          <button className='bton bton--md bton--primary' onClick={async (e)=>{
            e.preventDefault()
            const res = await addBooking(
              selectBranch,
              checkIn,
              checkOut,
              roomType,
              roomToBook,
              cookies.token
            )
            if(res){
              handleShow()
            }
          }}>
            Confirm Purchase
          </button>
        </section>
      </Container>
      {/* //modal content here */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        centered
        size='sm'
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <section className='bookingSuccess'>
            <div className='bookingSuccess-icon'>
              <IoIosCheckmarkCircleOutline />
            </div>
            <div className='bookingSuccess-message'>
              Your booking is successful
            </div>
            <div className='bookingSuccess-desc'>
              Visit your account to see your bookings
            </div>
            <div className='text-center mt-4'>
             
                <button className='bton bton--primary bton--md' onClick={(e)=>{
                  e.preventDefault()
                  navigate('/results', {state:{checkIn, checkOut, company:selectBranch}})
                }}>
                  Book more rooms
                </button>
              
            </div>
          </section>
        </Modal.Body>
      </Modal>
      ;
    </div>
  );
};

export default Payment;
