import React from 'react';
import { Container } from 'react-bootstrap';
import gql from 'graphql-tag';
import Query from '../../components/Query';
import { useParams, Link, useNavigate } from 'react-router-dom';

const Sweets = () => {
  const { id } = useParams();
  const GET_SWEET = gql`
    query NewQuery($id: ID!) {
      sweet(id: $id, idType: SLUG) {
        sweets {
          gallery {
            sourceUrl
          }
        }
      }
    }
  `;
  return (
    <Container>
      <h3 className='mt-5 text-center'>pauwa Chautari</h3>
      <div className='gallery__photos mt-5'>
        <Query query={GET_SWEET} id={id}>
          {({ data: { sweet } }) => {
            return (
              <>
                {sweet?.sweets?.gallery?.map((b) => {
                  return (
                    <figure className='gallery__photos--inner'>
                      <img src={b.sourceUrl} alt='' />
                    </figure>
                  );
                })}
              </>
            );
          }}
        </Query>
      </div>
    </Container>
  );
};

export default Sweets;
