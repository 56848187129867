import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import RomImage from '../../assets/images/brand/super-delux-rooms.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useBookingStore } from '../../stores/bookingStore';
import { useCookies } from 'react-cookie';

const Results = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const branches = useBookingStore((state) => state.branches)
  const fetchBranches = useBookingStore((state) => state.setBranch)

  const fetchAvailableRooms = useBookingStore((state) => state.setAvailableRoom)
  const availableRoom = useBookingStore((state) => state.availableRoomList)

  const [checkIn, setCheckIn] = useState(location.state.checkIn ?? "");
  const [checkOut, setCheckOut] = useState(location.state.checkOut ?? "");
  const [selectBranch, setSelectBranch] = useState(location.state.company ?? "");

  const [cookies] = useCookies(['token'])


  useEffect(() => {
    fetchAvailableRooms(checkIn, checkOut, selectBranch)
  }, [])

  return (
    <div>
      <Container>
        <section className='resultsWrapper'>
          <section className='resultsWrapper-title'>
            <div className='reservationform mt-3'>
              <Form>
                <div className='d-lg-flex  justify-content-between align-items-center'>
                  <div>
                    <Form.Label>Check In</Form.Label>

                    <Form.Control
                      type='date'
                      value={checkIn ?? Date.now()}
                      onChange={(e) => setCheckIn(e.target.value)}
                    />
                  </div>
                  <div>
                    <Form.Label>Check Out</Form.Label>

                    <Form.Control
                      type='date'
                      value={checkOut ?? Date.now()}
                      onChange={(e) => setCheckOut(e.target.value)}
                    />
                  </div>

                  <div>
                    <Form.Label>Select Branch</Form.Label>

                    <Form.Select aria-label='Default select example' value={selectBranch} onChange={(e) => setSelectBranch(e.target.value)}>
                      <option> Choose Branch</option>
                      {branches?.map((a) => {
                        return <option value={a.name}>{a.name}</option>;
                      })}
                    </Form.Select>
                  </div>

                  {/* <div>
                    <Form.Label>Select Room</Form.Label>

                    <Form.Select aria-label='Default select example'>
                      <option>Any room</option>
                    </Form.Select>
                  </div> */}

                  <div>
                    <Link to='/results'>
                      <button className='btonn bton--lg bton--primary mt-4' onClick={async (e) => {
                        e.preventDefault();
                        await fetchAvailableRooms(checkIn, checkOut, selectBranch)
                      }}>
                        Check &nbsp;Availability{' '}
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
            <hr />
            <h5>Results</h5>
          </section>
          <ul>
            {availableRoom && availableRoom.map((avr, index) => {
              return <li style={{ width: '45%' }}>
                <section className='resultsCard'>
                  <figure className='resultsCard-image'>
                    <img src={RomImage} alt='' />
                  </figure>
                  <section className='resultsCard-info'>
                    <div className='resultsCard-info-title'>
                      {' '}
                     {avr.room_type_name}
                    </div>

                    <div className='resultsCard-info-dates'>
                      {checkIn} to {checkOut}
                    </div>
                    <div className='resultsCard-info-occupancy'>
                      Occupancy {avr.maxGuest} person per room{' '}
                    </div>
                    <div className='resultsCard-info-rooms'>
                      {avr.availableRoom} rooms available
                    </div>
                    <div className='resultsCard-info-price'>Rs {avr.price}</div>

                   
                      <button className='bton bton--md bton--primary ms-0' onClick={(e)=>{
                        e.preventDefault()
                        if(!cookies.token){
                          navigate('/login', {state:{roomType: avr, checkIn, checkOut, selectBranch, fromBooking: true}})
                        }else {
                          navigate('/booking', {state:{roomType: avr, checkIn, checkOut, selectBranch}})
                        }
                      }}>
                        Book this room
                      </button>
                    
                  </section>
                </section>
              </li>
            })}

          </ul>
        </section>
      </Container>
    </div>
  );
};

export default Results;
